<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="人员管理"></page-header> -->
    <!-- <a-card :bordered="false"> -->

    <!-- <react-table
      :table-data="data"
      :total-items="totalItems"
      :show-total="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :scroll="{ x: 1400 }"
      :hideRowSelection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="data"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 按钮 -->
      <a-row :gutter="8">
        <!-- <a-col class="gutter-row" :span="4">
          <a-button
            v-if="isGranted('Pages.LGModul.Personal.Create')"
            type="primary"
            @click="createOrEdit(null, 'create')"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
          <a-button
            v-if="isGranted('Pages.LGModul.Personal.Export')"
            @click="exportToExcel()"
          >
            <a-icon type="file-excel" />
            <span>{{ l("Export") }}</span>
          </a-button>
        </a-col> -->
        <!-- <a-col class="gutter-row" :span="12">
            <a-input
              v-model="filterText"
              :placeholder="l('SearchWithThreeDot')"
              allow-clear
            />
          </a-col> -->
        <a-col class="gutter-row" :span="4">
          <a-select
            style="width: 100%"
            @change="selectChange"
            placeholder="请选择实名状态"
            v-model="isRealNamed"
            allowClear
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in realNamedStatusList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select
            style="width: 100%"
            @change="selectTypeChange"
            placeholder="请选择实名类型"
            v-model="verifiedWay"
            allowClear
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in realNameTypeList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select
            style="width: 100%"
            @change="selectStatusChange"
            placeholder="请选择启用状态"
            v-model="status"
            allowClear
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in statusList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="filterText"
            :placeholder="l('请输入姓名/手机号/身份证号')"
            allow-clear
          />
        </a-col>
        <!-- <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="idCard"
            :placeholder="l('请输入身份证号')"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="phoneNumber"
            :placeholder="l('请输入手机号码')"
            allow-clear
          />
        </a-col> -->
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="bankCardNo"
            :placeholder="l('请输入银行卡号')"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.LGModul.Personal.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="3" style="text-align: right">
          <a-button
            v-if="isGranted('Pages.LGModul.Personal.Export')"
            @click="exportToExcel()"
          >
            <a-icon type="file-excel" />
            <span>{{ l("Export") }}</span>
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
    <!-- </a-card> -->
           <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      :footer="null"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div style="text-align: center; position: relative">
        <a-spin :spinning="spinning" tip="加载中..." style="z-index: 1">
          <div style="height: 245px; width: 245px"></div>
        </a-spin>
        <div style="position: absolute; z-index: 2; top: 0; left: 55px">
          <img :src="inputModalParams.url" />
        </div>
        <div style="margin-top: 10px">
          姓名：{{ inputModalParams.desc.name }}
        </div>
        <div>手机号码：{{ inputModalParams.desc.code }}</div>
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy, initVerifiedWay } from "@/shared/common-service";
import { PersonnelServiceProxy } from "./services/personnel-proxies";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import CreateOrEditPersonnel from "./create-or-edit-personnel/create-or-edit-personnel";
import bankInfoList from "./bankInfo-list/bankInfo-list";
import presonnelSkillList from "./personnelSkills-list/personnelSkills-list";
import { personnelType } from "./services/enum/personnel.js";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import { initExtendArr, returnFilterData } from "./utils";
import ExtendInfo from "./extend-info/extend-info";
import PersonnelSurename from "./personnel-surename/personnel-surename.vue";
import BusMainInfo from "../projectuser/busmaininfo.vue";
import ClearRealName from "./clear-realname/clear-realname.vue";

export default {
  mixins: [AppComponentBase],
  name: "personnel-index",
  components: { EleTable, ExtendInfo },
  data() {
    return {
      spinning: false,
      selectedRowKeys: [],
      name: "",
      // 搜索
      filterText: "",
      realName: "",
      idCard: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [],
      // 用户数据
      data: [],
      _serviceProxy: "",
      commonService: null,
      realNamedStatusList: [
        // { value: "", label: "全部" },
        {
          value: true,
          label: "已实名",
        },
        {
          value: false,
          label: "未实名",
        },
      ],
      realNameTypeList: [
        // {
        //   value: "",
        //   label: "全部",
        // },
        {
          value: 4,
          label: "纯三要素",
        },
        {
          value: 5,
          label: "纯四要素",
        },
        {
          value: 9,
          label: "纯二要素",
        },
        {
          value: 0,
          label: "三要素+人脸",
        },
        {
          value: 2,
          label: "四要素+人脸",
        },
      ],
      statusList: [
        {
          value: 0,
          label: "禁用",
        },
        {
          value: 1,
          label: "启用",
        },
      ],
      verifiedWay: undefined,
      isRealNamed: undefined,
      status: undefined,
      phoneNumber: "",
      bankCardNo: "",
            inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 400,
        title: "人脸识别照片",
        url: "",
        desc: {
          nane: "",
          code: "",
        },
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.confirm = () => {};
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  created() {
    // console.log(personnelType);
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
    this.initColums();
  },
  methods: {
    initColums() {
      let _this = this;
      this.columns = [
        {
          title: "人员类型",
          dataIndex: "type",
          sorter: false,
          align: "center",
          fixed: "left",
          width: 120,
          customRender: (text) => {
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: personnelType[text],
              },
              attrs: { title: personnelType[text] },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "type" },
        },
        {
          title: "姓名",
          dataIndex: "realName",
          sorter: false,
          // align: "center",
          fixed: "left",
          width: 85,
          scopedSlots: { customRender: "realName" },
        },
        {
          title: "是否实名",
          dataIndex: "isRealNamed",
          sorter: true,
          align: "center",
          width: 100,
          type: "tag",
          scopedSlots: { customRender: "isRealNamed" },
          customRender: (text) => {
            if (text == "已实名") {
              return <a-tag color="#87d068">已实名</a-tag>;
            }
            return <a-tag color="#f50">未实名</a-tag>;
          },
        },
        {
          title: "实名类型",
          dataIndex: "verifiedWay",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "verifiedWay" },
          customRender: (text) => {
            if (text == "ThreeElements") {
              return "纯三要素";
            }
            if (text == "FourElements") {
              return "纯四要素";
            }
            if (text == "FaceRecognition") {
              return "人脸识别";
            }
            if (text == "ThreeElementsFaceRecognition") {
              return "三要素+人脸";
            }
            if (text == "FourElementsFaceRecognition") {
              return "四要素+人脸";
            }
            return "未实名";
          },
        },
        {
          title: "手机号码",
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          width: 130,
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: "身份证号码",
          dataIndex: "idCard",
          // sorter: true,
          // align: "center",
          width: 200,
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: "状态",
          dataIndex: "status",
          sorter: true,
          align: "center",
          width: 100,
          type: "tag",
          scopedSlots: { customRender: "status" },
          customRender: (text) => {
            if (text == "1") {
              return <a-tag color="#87d068">启用</a-tag>;
              // return <a-switch default-checked />;
            }
            return <a-tag color="#f50">禁用</a-tag>;
          },
        },
        {
          title: "银行卡号",
          dataIndex: "bankCardNo",
          // sorter: true,
          // align: "center",
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: "银行名称",
          dataIndex: "bankName",
          // sorter: true,
          // align: "center",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: "开户地",
          dataIndex: "bankArea",
          width: 150,
          // sorter: true,
          // align: "center",
          scopedSlots: { customRender: "bankArea" },
        },
        {
          title: this.l("新经济个体状态"),
          dataIndex: "commercialRegistStatus",
          sorter: true,
          align: "center",
          type: "custom",
          scopedSlots: { customRender: "commercialRegistStatus" },
          customRender: function (text, record, index) {
            if (text == "None") {
              return "未申请";
            } else if (text == "Applying") {
              return "已提交申请";
            } else if (text == "Registed") {
              return "待市监局审批";
            } else if (text == "Failed") {
              return "认证失败";
            } else if (text == "Terminating") {
              return "注销中";
            } else if (text == "Terminated") {
              return "已注销";
            }
            return "已下照";
          },
        },
        {
          title: this.l("申请时间"),
          dataIndex: "commercialApplyTime",
          sorter: true,
          align: "center",
          type: "custom",
          scopedSlots: { customRender: "commercialApplyTime" },
          customRender: function (text, record, index) {
            if (text && text != null) {
              return text.replace("T", " ").substring(0, 19);
            }
            return "";
          },
        },
        {
          title: this.l("完成时间"),
          dataIndex: "commercialRegistTime",
          sorter: true,
          align: "center",
          type: "custom",
          scopedSlots: { customRender: "commercialRegistTime" },
          customRender: function (text, record, index) {
            if (text && text != null) {
              return text.replace("T", " ").substring(0, 19);
            }
            return "";
          },
        },
         {
          title: "工作城市",
          dataIndex: "workCity",
          // sorter: true,
          // align: "center",
          width: 200,
          scopedSlots: { customRender: "workCity" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          // sorter: true,
          // align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },

    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "personal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.LGModul.Personal.Edit"),
              name: this.l("Edit"),
              icon: "profile",
              width: "50%",
              fn: (data) => {
                _this.editPersonnel(data);
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Personnel.BankInfo"),
              name: this.l("银行卡信息"),
              icon: "edit",
              width: "50%",
              fn: (data) => {
                _this.bankInfoList(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.UploadIdCardInfo"
              ),
              name: this.l("身份证信息"),
              icon: "idcard",
              width: "50%",
              fn: (data) => {
                _this.uploadModal(data, "edit");
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.ViewPersonnelSkills"
              ),
              name: this.l("人员技能"),
              icon: "profile",
              width: "50%",
              fn: (data) => {
                _this.personnelSkillList(data);
              },
            },
            // { granted: true, name: this.l("查看身份证"), icon: "idcard", fn: (data) => {
            //   _this.uploadModal(data, 'preview');
            // } },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.EditDynemicExtendInfo"
              ),
              name: this.l("动态扩展信息"),
              icon: "build",
              width: "50%",
              fn: (data) => {
                _this.createReactModify(data, "edit");
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.ViewDynemicExtendInfo"
              ),
              name: this.l("查看扩展信息"),
              icon: "container",
              width: "50%",
              fn: (data) => {
                _this._getByPersonnelId(data, "others");
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.UnbindWeChatMpOpenId"
              ),
              name: this.l("微信解绑"),
              icon: "disconnect",
              fn: (data) => {
                abp.message.confirm(
                  "是否确定解除微信绑定？",
                  "系统提示",
                  (result) => {
                    if (result) {
                      _this._unbindWeChatMpOpenId(data.id);
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.UnbindIdcardInfo"
              ),
              name: this.l("个人信息解绑"),
              icon: "disconnect",
              fn: (data) => {
                abp.message.confirm(
                  "是否确定解除个人信息绑定？",
                  "系统提示",
                  (result) => {
                    if (result) {
                      _this._unbindIdcardInfo(data.id);
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Personnel.UnbindMobile"),
              name: this.l(" 手机号解绑"),
              icon: "disconnect",
              fn: (data) => {
                abp.message.confirm(
                  "是否确定解除手机号绑定？",
                  "系统提示",
                  (result) => {
                    if (result) {
                      _this._unbindMobile(data.id);
                    }
                  }
                );
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.Personnel.RealName"),
              name: this.l("港澳台实名认证"),
              icon: "profile",
              fn: (data) => {
                debugger;
                if (data && data.isRealNamed == "已实名") {
                  _this.$message.info("本人已实名");
                } else {
                  _this.personnelSurename(data);
                }
              },
            },
            {
              granted: this.isGranted("Pages.Personnel.CommericalInfo"),
              name: this.l("新经济个体信息"),
              icon: "idcard",
              fn: (data) => {
                _this.showMainInfo(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.Personal.Clear.RealName.ChangeMobile"
              ),
              name: this.l("重置实名"),
              icon: "disconnect",
              fn: (data) => {
                _this.showClearRealName(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModul.Personnel.PersonnelFaceImage"
              ),
              name: this.l("人脸识别照片"),
              icon: "user",
              fn: (data) => {
                _this._getPersonnelFaceImageUrl(data);
 
              },
            },
          ],
          // delete: { granted: true, name: this.l("Delete"), fn: (data) => {
          //   _this.deletePersonnel(data)
          // } },
          delete: {
            granted: this.isGranted("Pages.LGModul.Personal.EnabledDisabled"),
            width: "50%",
            name: this.l("禁/启用"),
            tipsDesc: "禁/启用人员?",
            fn: (data) => {
              _this._changeStatus(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    showMainInfo(item) {
      ModalHelper.create(
        BusMainInfo,
        {
          id: item.id,
        },
        {
          isChange: true,
          width: "850px",
        }
      ).subscribe((res) => {});
    },
    showClearRealName(item) {
      ModalHelper.create(
        ClearRealName,
        {
          id: item.id,
        },
        {
          isChange: true,
          width: "850px",
        }
      ).subscribe((res) => {
         if (res) {
          this.getData();
        }
      });
    },
    showImgInfo(item) {
      const list = [
        {
          label: "身份证正面照片",
          value: [],
          type: "upload",
          disabled: false,
          required: true,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[0].value = fileList;
          },
        },
        {
          label: "身份证反面照片",
          value: [],
          type: "upload",
          disabled: false,
          required: true,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[1].value = fileList;
          },
        },
        {
          label: "银行卡照片",
          value: [],
          type: "upload",
          disabled: false,
          required: true,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[2].value = fileList;
          },
        },
      ];
      let reqParams = [];
      if (item && item.id) {
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: "edit", // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: "/api/services/app/Personnel/GetNewEconomyImageInfo",
              save: "/api/services/app/Personnel/SaveNewEconomyInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              if (res.idCardFont && res.idCardFont.indexOf(",") !== -1) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[0].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.idCardFont) {
                let n = res.idCardFont.split("/");
                list[0].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.idCardFont}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.idCardFont } },
                  status: "done",
                });
              }
              if (res.idCardBack && res.idCardBack.indexOf(",") !== -1) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.idCardBack) {
                let n = res.idCardBack.split("/");
                list[1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.idCardBack}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.idCardBack } },
                  status: "done",
                });
              }
              if (res.bankCardFont && res.bankCardFont.indexOf(",") !== -1) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[list.length - 1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.bankCardFont) {
                let n = res.bankCardFont.split("/");
                list[list.length - 1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.bankCardFont}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.bankCardFont } },
                  status: "done",
                });
              }
            },
            submitfn: async (values, queryParams) => {
              if (!list[list.length - 1].value.length) {
                this.$message.info("请上传附件资源");
                return;
              }
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              let idCardPhotoFront = list[0].value[0].response.data.Path;
              let idCardPhotoVerso = list[1].value[0].response.data.Path;
              let personnelPhoto = list[2].value[0].response.data.Path;

              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    idCardFont: idCardPhotoFront,
                    idCardBack: idCardPhotoVerso,
                    bankCardFont: personnelPhoto,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.realName = "";
      this.idCard = "";
      this.filterText = "";
      (this.isRealNamed = ""), this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      console.log(page);
      console.log(pageSize);
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    uploadModal(item, type) {
      const list = [
        {
          label: "身份证正面照片",
          value: [],
          type: "upload",
          disabled: false,
          required: false,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: item.isRealNamed === "已实名" ? "0" : "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[0].value = fileList;
          },
        },
        {
          label: "身份证反面照片",
          value: [],
          type: "upload",
          disabled: false,
          required: false,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: item.isRealNamed === "已实名" ? "0" : "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[1].value = fileList;
          },
        },
        {
          label: "半身照",
          value: [],
          type: "upload",
          disabled: false,
          required: false,
          rules: [],
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          showRemoveIcon: item.isRealNamed === "已实名" ? "0" : "1",
          span: 24,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[2].value = fileList;
          },
        },
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [
              ...reqParams,
              // {key: `${this.reqKey === 'Organization' ? 'organizationUnitId' : 'customerId'}`, value: this.id},
            ],
            reqUrl: {
              getById: "/api/services/app/Personnel/GetPersonnelById",
              save: "/api/services/app/Personnel/UpdateIdCardPhotoInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              // form.setFieldsValue({...res})
              if (
                res.idCardPhotoFront &&
                res.idCardPhotoFront.indexOf(",") !== -1
              ) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[0].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.idCardPhotoFront) {
                let n = res.idCardPhotoFront.split("/");
                list[0].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.idCardPhotoFront}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.idCardPhotoFront } },
                  status: "done",
                });
              }
              if (
                res.idCardPhotoVerso &&
                res.idCardPhotoVerso.indexOf(",") !== -1
              ) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.idCardPhotoVerso) {
                let n = res.idCardPhotoVerso.split("/");
                list[1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.idCardPhotoVerso}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.idCardPhotoVerso } },
                  status: "done",
                });
              }
              if (
                res.personnelPhoto &&
                res.personnelPhoto.indexOf(",") !== -1
              ) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[list.length - 1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.personnelPhoto) {
                let n = res.personnelPhoto.split("/");
                list[list.length - 1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.personnelPhoto}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.personnelPhoto } },
                  status: "done",
                });
              }
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              // if (!list[list.length - 1].value.length) {
              //   this.$message.info("请上传附件资源");
              //   return;
              // }
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              const idCardPhotoFront = list[0].value.length
                ? list[0].value[0].response.data.Path
                : null;
              const idCardPhotoVerso = list[1].value.length
                ? list[1].value[0].response.data.Path
                : null;
              const personnelPhoto = list[2].value.length
                ? list[2].value[0].response.data.Path
                : null;

              try {
                // console.log({
                //   url: queryParams.reqUrl.save,
                //   params: {...values, pictureUrls: pictureUrls, status: Number(values.status)},
                // })
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    idCardPhotoFront: idCardPhotoFront,
                    idCardPhotoVerso: idCardPhotoVerso,
                    personnelPhoto: personnelPhoto,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    // 静态扩展信息
    async _getByPersonnelId(item, type) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/PersonnelExtend/GetByPersonnelId",
          params: { personnelId: item.id },
        });
        this.spinning = false;
        // console.log(await initExtendArr(res))
        // let arr = await initExtendArr(res)
        this.previewExtendInfo(item, type, res);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async previewExtendInfo(item, type, resData) {
      console.log("first",resData)
      let list = await initExtendArr(resData);
      console.log("list:", list);
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.id) {
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ExtendInfo,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: "",
              save: "",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              // console.log(resData)
              for (let k in resData) {
                let _obj = resData[k];
                // form.setFieldsValue({
                //   ..._obj
                // })
                if (
                  resData[k] instanceof Array === false &&
                  k !== "personnelExtendRelevantAttachment"
                ) {
                  for (let key in _obj) {
                    if (
                      key === "hadChild" ||
                      key === "isMarried" ||
                      key === "isFirstAccumulationFund" ||
                      key === "isFirstSocialSecurity"
                    ) {
                    } else {
                      this.$nextTick(() => {
                        // console.log(key, _obj[key])
                        // form.setFieldsValue({
                        //   key: _obj[key]
                        // })
                        // console.log(form.getFieldsValue())
                      });
                    }
                  }
                } else {
                }
              }
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              // ...
              try {
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    // 动态扩展信息
    async createReactModify(item, type) {
      let list = [];
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.id) {
        // console.log(item)
        reqParams.push({ key: "id", value: item.id });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: "/api/services/app/Personnel/GetExtendInfoById",
              save: "/api/services/app/Personnel/SaveExtendInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              res.formItems.map((_item, index) => {
                switch (_item.itemType) {
                  case "Text":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonInput",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Textarea":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "textarea",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Number":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "numberInput",
                      disabled: false,
                      required: _item.isRequire,
                      max: _item.maxLength,
                      min: 0,
                      key: _item.key,
                      rules: [],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  case "Select":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonSelect",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: "",
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Checkbox":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "checkbox",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Radio":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "radio",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultValue: null,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Date":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "datePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  case "Upload":
                    list.push({
                      label: _item.name,
                      value: [],
                      type: "upload",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [],
                      subdirName: environment.production ? "abpProd" : "abpDev",
                      uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
                      limitedLength: _item.maxLength,
                      span: 24,
                      changeFn: (info) => {
                        let fileList = [...info.fileList];
                        fileList = fileList.map((file) => {
                          if (file.response) {
                            file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                          }
                          return file;
                        });
                        list[_item.sort + orginListLength].value = fileList;
                      },
                    });
                    break;
                  case "DateRange":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "rangeDatePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "array",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      defaultValue: null,
                      changeFn: () => {},
                    });
                    break;
                  default:
                    throw new Error("暂无匹配项目");
                }
              });

              if (!item) {
              } else {
                form.setFieldsValue({
                  ProjectName: item.projectName,
                  ProjectType: item.projectType,
                  OrganizationUnitId: item.organizationUnitId,
                  ProjectTime: [item.beginDate, item.endDate],
                  CustomerName: item.customerName,
                  ProjectCode: item.projectCode,
                });
              }

              if (res.results && Object.keys(res.results).length !== 0) {
                const oResult = res.results;
                let opts = {};
                list.map((it) => {
                  if (it.key) {
                    let k = it.key;
                    if (it.type === "upload") {
                      if (oResult[k].value.indexOf(",") !== -1) {
                        let arr = oResult[k].value.split(",");
                        arr.map((val, i) => {
                          let n = val.split("/");
                          it.value.push({
                            uid: `-${i + 1}`,
                            url: `${AppConsts.uploadBaseUrl}${val}`,
                            name: n[n.length - 1],
                            response: { data: { Path: val } },
                            status: "done",
                          });
                        });
                      } else {
                        if (oResult[k].value && oResult[k].value.length) {
                          let n = oResult[k].value.split("/");
                          it.value.push({
                            uid: `-1`,
                            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                            name: n[n.length - 1],
                            response: { data: { Path: res.pictureUrls } },
                            status: "done",
                          });
                        }
                      }
                    } else if (it.type === "rangeDatePicker") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf("至") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split("至");
                    } else if (it.type === "datePicker") {
                      opts[`${k}`] = oResult[k].value
                        ? moment(oResult[k].value, "YYYY-MM-DD")
                        : null;
                    } else if (it.type === "checkbox") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf(",") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split(",");
                    } else {
                      opts[`${k}`] = oResult[k].value;
                    }
                  }
                });
                // console.log(opts)
                this.$nextTick(() => {
                  form.setFieldsValue({ ...opts });
                });
              }

              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let fileUrl = [];
              for (let i = 0; i < list.length; i++) {
                if (list[i].type === "upload" && list[i].required) {
                  if (!list[i].value.length) {
                    this.$message.info(`请上传${list[i].label}的附件资源`);
                    break;
                  }
                }
              }

              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              let dynamicList = list.filter((item) => item.key);
              let _results = [];
              // console.log(dynamicList)

              dynamicList.map((_item) => {
                if (
                  _item.type === "commonInput" ||
                  _item.type === "numberInput" ||
                  _item.type === "textarea" ||
                  _item.type === "radio" ||
                  _item.type === "commonSelect"
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`]
                        ? values[`${_item.value}`]
                        : null,
                    },
                  });
                } else if (_item.type === "datePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      // value: values[`${_item.value}`].format('YYYY-MM-DD')
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "rangeDatePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`][0] +
                        "至" +
                        values[`${_item.value}`][1],
                    },
                  });
                } else if (_item.type === "checkbox") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`].length === 1
                          ? values[`${_item.value}`][0]
                          : values[`${_item.value}`].join(","),
                    },
                  });
                } else if (_item.type === "upload") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        _item.value.length === 1
                          ? `${_item.value[0].response.data.Path}`
                          : _item.value
                              .map((v) => {
                                return v.response.data.Path;
                              })
                              .join(","),
                    },
                  });
                }
              });

              let submitData = {
                personnelId: item && item.id ? item.id : null,
                results: _results,
              };
              // console.log('submitData:', submitData)

              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...submitData },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    async _changeStatus(id) {
      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url: "/api/services/app/Personnel/ChangeStatus",
          params: { id },
        });
        this.getData();
        this.$notification["success"]({
          message: this.l("更新人员成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      // console.log(this.request)
      this._serviceProxy
        .getPaged(
          !this.verifiedWay && this.verifiedWay!=0 ? "" : this.verifiedWay,
          this.bankCardNo,
          // this.realName,
          // this.phoneNumber,
          // this.idCard,
          this.status === "" || this.status == undefined ? "" : this.status,
          this.isRealNamed === "" || this.isRealNamed == undefined
            ? ""
            : this.isRealNamed == 1
            ? true
            : false,
          this.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        )
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          this.data = result.items.map((o) => {
            (o.typeStr = personnelType[o.type]),
              (o.isRealNamed = o.isRealNamed ? "已实名" : "未实名");
            return {
              ...o,
              verifiedWay: initVerifiedWay(o.verifiedWay),
              isRealNamedColor: o.isRealNamed === "已实名" ? "#87d068" : "#f50",
              type: personnelType[o.type],
              status: o.status == "1" ? "启用" : "禁用",
              statusColor: o.status == "1" ? "#87d068" : "#f50",
            };
          });
          // console.log(this.data);
          this.totalItems = result.totalCount;
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          this.totalPages = Math.ceil(
            result.totalCount / this.request.maxResultCount
          );
        });
    },
    async _unbindWeChatMpOpenId(personnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/UnbindWeChatMpOpenId",
          params: { personnelId: personnelId },
        });
        this.spinning = false;
        this.notify.success("解绑成功！");
        this.getData();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _unbindIdcardInfo(personnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/UnbindIdcardInfo",
          params: { personnelId: personnelId },
        });
        this.spinning = false;
        this.notify.success("解绑成功！");
        this.getData();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _unbindMobile(personnelId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/UnbindMobile",
          params: { personnelId: personnelId },
        });
        this.spinning = false;
        this.notify.success("解绑成功！");
        this.getData();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * 选中table
     */
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChange(pagination, filters, sorter) {
      console.log("Various parameters", pagination, filters, sorter);
      // this.filteredInfo = filters;
      // this.sortedInfo = sorter;
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      (this.status = undefined), (this.isRealNamed = undefined);
      (this.realName = ""),
        (this.phoneNumber = ""),
        (this.idCard = ""),
        (this.verifiedWay = undefined),
        (this.bankCardNo = ""),
        (this.request.skipCount = 0);
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(item, type) {
      ModalHelper.create(
        CreateOrEditPersonnel,
        {
          query: {
            id: item ? item.id : "",
            // isRealNamed: item?item.isRealNamed:false,
            isRealNamed: item && item.isRealNamed == "已实名" ? true : false,
          },
        },
        {
          width: "800px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    editPersonnel(item) {
      console.log(item);
      this.createOrEdit(item, "create");
    },
    bankInfoList(data) {
      ModalHelper.create(
        bankInfoList,
        {
          id: data.id,
          isRealNamed: data.isRealNamed === "已实名" ? "1" : "0",
        },
        {
          width: "900px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    personnelSkillList(data) {
      ModalHelper.create(
        presonnelSkillList,
        {
          id: data.id,
        },
        {
          width: "900px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    deletePersonnel(data) {
      let id = data.id;
      this._serviceProxy
        .deletePersonnel(id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    clearData() {
      this.selectedRowKeys = [];
    },
    refreshData() {
      this.getData();
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      // this.spinning = true;
      // this._serviceProxy.getToExcelFile().then((result) => {
      //   this.spinning = false;

      //   fileDownloadService.downloadTempFile(result);
      // });
      let data = {
        verifiedWay: !this.verifiedWay ? "" : this.verifiedWay,
        bankCardNo: this.bankCardNo,
        status:
          this.status === "" || this.status == undefined ? "" : this.status,
        isRealNamed:
          this.isRealNamed === "" || this.isRealNamed == undefined
            ? ""
            : this.isRealNamed == 1
            ? true
            : false,
        filterText: this.filterText,
        sorting: this.request.sorting,
        personnelTag: 1,
      };
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Personnel/GetToExcelFile",
          method: "GET",
          params: data,
        })
        .then((result) => {
          this.spinning = false;
          fileDownloadService.downloadTempFile(result.data);
        })
        .catch((e) => {
          this.spinning = false;
          console.error(e);
        });
    },
    selectChange(value) {
      this.isRealNamed = value;
    },
    selectTypeChange(value) {
      this.verifiedWay = value;
    },
    selectStatusChange(value) {
      this.status = value;
    },
    personnelSurename(item) {
      console.log(item);
      ModalHelper.create(
        PersonnelSurename,
        {
          query: {
            id: item ? item.id : "",
            // isRealNamed: item?item.isRealNamed:false,
            isRealNamed: item && item.isRealNamed == "已实名" ? true : false,
          },
        },
        {
          width: "800px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async _getPersonnelFaceImageUrl(data) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Personnel/GetPersonnelFaceImageUrl",
          params: { id: data.id },
        });
        console.log(res);
                this.spinning = false;
        if(!res)
        {
          abp.message.warn('暂无人脸照片！')
          return;
        }
        this.inputModalParams.desc.name = data.realName;
        this.inputModalParams.desc.code = data.phoneNumber;
        // this.inputModalParams.desc.code = `${AppConsts.uploadBaseUrl}${res}`;
        this.inputModalParams.url = `${AppConsts.uploadBaseUrl}${res}`;
        this.inputModalParams.visible = true;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.btn--footer {
  // border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-delete {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.btn--footer {
  margin-bottom: 20px;
}
</style>
