<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>新经济个体商事主体信息</span>
      </div>
    </div>
    <div class="m-container">
      <div class="item">
        <div class="title">基本信息</div>
        <div class="body">
          <table class="item-table" border="1" cellspacing="0" cellpadding="0">
            <tr>
                <td class="tdheader">商事主体类型</td>
                <td style="width:34%">{{info.companyType}}</td>
                <td class="tdheader">统一社会信用代码</td>
                <td style="width:34%">{{info.socialCode}}</td>
            </tr>
            <tr>
                <td class="tdheader">联系人</td>
                <td style="width:34%">{{info.contactPerson}}</td>
                <td class="tdheader">联系人手机</td>
                <td style="width:34%">{{info.contactPhone}}</td>
            </tr>
            <tr>
                <td class="tdheader">报备单</td>
                <td style="width:34%">{{info.orderName}}</td>
                <td class="tdheader">报备单号</td>
                <td style="width:34%">{{info.orderNo}}</td>
            </tr>
            <tr>
                <td class="tdheader">商事主体信息</td>
                <td colspan="3">{{info.businessRegisterName}}</td>
            </tr>
            <tr>
                <td class="tdheader">经营住所</td>
                <td colspan="3">{{info.clusterAddress}}</td>
            </tr>
            <tr>
                <td class="tdheader">经营场所</td>
                <td colspan="3">{{info.operateAddress}}</td>
            </tr>
            <tr>
                <td class="tdheader">经营范围</td>
                <td colspan="3">{{info.opscope}}</td>
            </tr>
            <tr>
                <td class="tdheader">成立日期</td>
                <td colspan="3">{{info.estdate}}</td>
            </tr>
            
          </table>
        </div>
      </div>

      <div class="item">
        <div class="title">法人信息</div>
        <div class="body">
          <table class="item-table" border="1" cellspacing="0" cellpadding="0">
            <tr>
                <td class="tdheader">法人姓名</td>
                <td style="width:34%">{{info.managerName}}</td>
                <td class="tdheader">法人身份证号</td>
                <td style="width:34%">{{info.managerIdNo}}</td>
            </tr>
            <tr>
                <td class="tdheader">法人手机号</td>
                <td style="width:34%">{{info.managerPhone}}</td>
                <td class="tdheader">政治面貌</td>
                <td style="width:34%">{{info.managerPolitical}}</td>
            </tr>
            <tr>
                <td class="tdheader">教育程度</td>
                <td style="width:34%">{{info.managerEducation}}</td>
                <td class="tdheader">申请前职业状态</td>
                <td style="width:34%">{{info.managerJobStatus}}</td>
            </tr>
            <tr style="display:none;">
                <td class="tdheader">身份证正面</td>
                <td style="width:34%">{{info.managerIdFront}}</td>
                <td class="tdheader">身份证反面</td>
                <td style="width:34%">{{info.managerIdBack}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="item">
        <div class="title">银行卡信息</div>
        <div class="body">
          <table class="item-table" border="1" cellspacing="0" cellpadding="0">
            <tr>
                <td class="tdheader">银行名称</td>
                <td style="width:34%">{{info.managerBank}}</td>
                <td class="tdheader">银行类型</td>
                <td style="width:34%">{{info.managerBankType}}</td>
            </tr>
            <tr>
                <td class="tdheader">银行卡号</td>
                <td style="width:34%">{{info.managerBankNo}}</td>
                <td class="tdheader">开户日期</td>
                <td style="width:34%">{{info.managerBankOpenDate}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";

export default {
  mixins: [ModalComponentBase],
  name: "busmaininfo",
  components: {},
  data: function () {
    return {
      spinning: false,
      info: {
        businessRegisterName: '',
        socialCode: '',
        clusterAddress: '',
        operateAddress: '',
        companyType: '',
        opscope: '',
        contactPerson: '',
        contactPhone: '',
        estdate: '',
        managerName: '',
        managerIdNo: '',
        managerPhone: '',
        orderName: '',
        orderNo: '',

        managerName: '',
        managerIdNo: '',
        managerPhone: '',
        managerPolitical: '',
        managerEducation: '',
        managerAddress: '',
        managerJobStatus: '',
        managerIdFront: '',
        managerIdBack: '',

        managerBankType: '',
        managerBank: '',
        managerBankNo: '',
        managerBankOpenDate: '',

      }
    }
  },
  created() {
    this.fullData();
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.spinning = true;
      let url = this.$apiUrl + "/api/services/app/CommericalRegist/GetBusinessMainInfo";
      let options = {
        params: {
          id: this.id,
        },
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .then((response) => {
          let res = response.data;
          if (!res) {
            return false;
          }
          this.info = res;
        })
        .catch((e) => {
          console.error(e);
          abp.message.warn(this.l("DefaultErrorMessage"));
        })
        .finally(() => {
          this.spinning = false;
        });
    }
  }
}
</script>
<style>
.m-container{
  width: 100%;
  height: 100%;
  background-color: white;
}
.item{
  width:100%;
  margin: 0 auto;
  margin-top: 15px;
}
.title{
  text-indent: 10px;
  background-color: rgb(24 144 255 / 20%);
  color:#333;
  padding:5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-weight: 600;
}
.body{
  width: 100%;
}
.item-table{
  text-align:center;
  border-color:#ccc;
  width: 100%;
}
.item-table > tr > td{
  height: 34px;
}
.tdheader{
  background-color: #eee;
  width:16%;
}
</style>


